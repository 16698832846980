import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import SessionService from "../service/SessionService";

function Footer() {
	const userInfo = SessionService.getUserInfo();

	// console.log(userInfo);

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			<div className="rto_section">
				<div>
					<div style={{ display: "inline-block", color: "#22557b" }}>
						Copyright © RTO Mobile.
					</div>{" "}
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<div style={{ display: "inline-block" }}>
						<Button
							size="xs"
							variant="primary"
							onClick={handleShow}
						>
							Contact Support
						</Button>
					</div>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<div style={{ display: "inline-block" }}>
						<a
							href="https://www.rtomobile.com/terms-conditions"
							target="_blank"
							rel="noreferrer"
							style={{ color: "#22557b" }}
						>
							Terms & Conditions
						</a>
					</div>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<div style={{ display: "inline-block", color: "#22557b" }}>
						Version 2.2.1
					</div>
				</div>
			</div>
			<Modal size={"lg"} show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title className="w-100">
						<div className="text-center">RTO Mobile Support</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div style={{ overflowX: "auto" }}>
						<table className="table table-bordered">
							<tbody>
								<tr>
									<td>Organization:</td>
									<td colSpan="4">
										{userInfo?.organization?.name}
									</td>
								</tr>
								<tr></tr>
								<tr>
									<td>Store:</td>
									<td colSpan="4">{userInfo?.store?.name}</td>
								</tr>
								<tr></tr>
								<tr></tr>
								<tr>
									<td colSpan="5" className="text-center">
										Customer Service Hours
									</td>
								</tr>
								<tr></tr>
								<tr>
									<td>&nbsp;</td>
									<td className="text-center">Eastern</td>
									<td className="text-center">Central</td>
									<td className="text-center">Mountain</td>
									<td className="text-center">Pacific</td>
								</tr>
								<tr></tr>
								<tr>
									<td nowrap="">Monday - Friday</td>
									<td className="text-center">
										9:00am - 10:00pm
									</td>
									<td className="text-center">
										8:00am - 9:00pm
									</td>
									<td className="text-center">
										7:00am - 8:00pm
									</td>
									<td className="text-center">
										6:00am - 7:00pm
									</td>
								</tr>
								<tr></tr>
								<tr>
									<td>Saturday</td>
									<td className="text-center">
										11:00am - 8:00pm
									</td>
									<td className="text-center">
										10:00am - 7:00pm
									</td>
									<td className="text-center">
										9:00am - 6:00pm
									</td>
									<td className="text-center">
										8:00am - 5:00pm
									</td>
								</tr>
								<tr></tr>
								<tr>
									<td>Sunday</td>
									<td className="text-center">
										12:00pm - 8:00pm
									</td>
									<td className="text-center">
										11:00am - 7:00pm
									</td>
									<td className="text-center">
										10:00am - 6:00pm
									</td>
									<td className="text-center">
										9:00am - 5:00pm
									</td>
								</tr>
								<tr></tr>
								<tr>
									<td nowrap="">Support Phone:</td>
									<td colSpan="4">210-809-4800</td>
								</tr>
								<tr>
									<td nowrap="">Support Email:</td>
									<td colSpan="4">support@rtomobile.com</td>
								</tr>
								<tr></tr>
								<tr></tr>
							</tbody>
						</table>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default Footer;
