import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Box } from "@mui/material";
import "./Resources.css";

function ReferralProgramRules({ loading, setLoading }) {
	return (
		<>
			<div className="rto_form-sections">
				<div className="rto_content-div" style={{ maxWidth: "1600px" }}>
					<Box
						display="flex"
						justifyContent="flex-end"
						backgroundColor="#f5f5f5"
						p={(0.5, 1)}
						borderRadius={1}
					>
						<Breadcrumbs aria-label="breadcrumb">
							<Link to="/dashboard" className="rto_link-2">
								Dashboard
							</Link>
							<Link to="/resources" className="rto_link-2">
								Resources
							</Link>
							<Typography>Referral Program Rules</Typography>
						</Breadcrumbs>
					</Box>

					<div
						className="rto_title-div"
						style={{ textAlign: "left" }}
					>
						<h1>RTO MOBILE MERCHANT REFERRAL PROGRAM RULES</h1>

						<p>
							<strong>
								Effective Date: October 10, 2019 and Until
								Further Notice
							</strong>
						</p>
						<p>
							<strong>Program Overview:</strong> The RTO Mobile
							Referral Program (the “Program”) is offered by RTO
							Mobile and provides the opportunity to refer your
							friends, colleagues, and associates to RTO Mobile
							and receive compensation. You must be an RTO Mobile
							customer to participate in the Referral Program. If
							you are not a store owner and/or RTO Mobile
							customer, you may be eligible to refer store owners
							under other referral programs offered from time to
							time by RTO Mobile.
						</p>
						<p>
							<strong>Program Details:</strong> RTO Mobile offers
							a reward (“Reward”) to any Eligible Referrer (as
							defined below) who refers an Eligible New Customer
							(as defined below) in compliance with these Program
							Rules (“Rules”). Rewards will be calculated based on
							the number of Eligible New Customers referred by you
							subject to the Rules. The Reward will be issued only
							after the Eligible New Customer’s account has been
							active and in good standing and has at least five
							(5) rental contracts originated within the RTO
							Mobile platform.
						</p>
						<p>
							A W-9 tax form must be completed and submitted prior
							to issuance of the Reward. RTO Mobile reserves the
							right to add, modify and/or delete any Rewards
							and/or requirements to receive any Rewards. RTO
							Mobile’s decision with respect to all aspects of the
							Referral Program is final and binding.
						</p>
						<p>
							<strong>Definition of an Eligible Referrer:</strong>{" "}
							To qualify as an “Eligible Referrer”, you must be an
							existing RTO Mobile merchant, in good standing at
							the time of the referral and must remain active and
							in good standing for a minimum of (30) days after
							the account for the Eligible New Customer that you
							referred has been activated, your existing account
							must be active having originated at least 1 rental
							contract on the RTO Mobile platform within the last
							60 days. Existing RTO Mobile customers, and former
							RTO Mobile customers are prohibited from referring
							themselves. Employees are prohibited from referring
							their respective employers and are ineligible to
							receive a reward.
						</p>
						<p>
							<strong>
								Definition of an Eligible New Customer:
							</strong>{" "}
							An “Eligible New Customer” is a business that (1) is
							not currently, and has not been, a RTO Mobile
							customer for at least the previous 12 consecutive
							months, (2) is not already registered as a RTO
							Mobile working or active lead in RTO Mobile’s
							records, (3) applies and is accepted by RTO Mobile
							for the RTO Mobile platform while this Referral
							Program is still active, (4) maintains an active RTO
							Mobile account in good standing in compliance with
							RTO Mobile's Terms of Service.
						</p>
						<p>
							<strong>How to Earn Rewards:</strong> In order to be
							eligible to earn Rewards, you must complete the
							referral form at www.RTO Mobile.com/referral
							(“Referral Website”). Once you have completed the
							referral form RTO Mobile will contact the person at
							the business you refer and explain the benefits of
							the RTO Mobile solutions that are available. If we
							are able to contact the business and they sign up
							for the RTO Mobile platform, and if that referred
							business ultimately qualifies as an Eligible New
							Customer, and originates at least 5 rental contracts
							on the RTO Mobile platform, you will be eligible for
							a Reward in the amount of $100 for the referral. The
							Reward will be issued in the form of a credit to
							your existing account in accordance with the Reward
							Issuance section below (assuming you meet the
							definition of Eligible Referrer above).
						</p>
						<p>
							If an Eligible New Customer is referred by more than
							one Eligible Referrer, only the original Eligible
							Referrer validated by RTO Mobile shall be entitled
							to receive the Reward.
						</p>
						<p>
							By agreeing to these terms and conditions, you agree
							not to abuse the tools in the Referral Website by
							conduct which is detrimental to the interests of RTO
							Mobile, including without limitation, attempting to
							make referrals or receive rewards in a manner
							inconsistent with these Rules or the intent of these
							Rules, attempting to earn rewards through
							illegitimate channels, participating in fraud, or
							tampering with Referral Program or its tools.
						</p>
						<p>
							<strong>Reward Issuance:</strong> While the form of
							reward is a credit memo to your account and may be
							applied only to that account towards RTO Mobile fee
							charges, the reward is considered compensation for
							services, and therefore a W-9 form must be
							completed, submitted and approved for all Rewards,
							prior to issuance of Reward. The W-9 form must match
							the name of the person or company receiving the
							award. If a valid W-9 form has not been received and
							approved within one hundred eighty (180) days of
							Reward eligibility, the Reward will be forfeited. In
							the event the Eligible Referrer's account has a past
							due balance, that balance will be deducted from the
							Reward. Rewards are considered taxable income and
							are subject too local, state and federal taxes. When
							the value of the reward items for which you have
							earned reaches $600 or more in a calendar year, a
							1099 tax form will be generated and mailed to you
							for tax reporting purposes. RTO Mobile recommends
							that you keep all statements sent to you for your
							record keeping purposes.
						</p>
						<p>
							<strong>General Conditions:</strong> By
							participating in the Program, you agree (i) to
							release, discharge, indemnify and hold harmless RTO
							Mobile, its parent, subsidiaries, affiliates,
							retailers, and advertising and promotion agencies,
							and all of their respective officers, directors,
							shareholders, employees and agents (collectively,
							“Released Parties”) from any liability or damages
							which may arise out of participation in the Referral
							Program or out of the acceptance, use, misuse or
							possession of any Reward or products attained
							through this Referral Program.
						</p>
						<p>
							All applicable federal, state and local laws and
							regulations apply. Released Parties are not
							responsible for late, lost, incomplete, delayed,
							inaccurate, garbled, undelivered, misdirected
							referrals or Rewards or other errors or problems of
							any kind relating to or in connection with the
							Referral Program, whether printing, typographical,
							technical, computer, network, human, mechanical,
							electronic or otherwise, including, without
							limitation, errors or problems which may occur in
							connection with the administration of the Program,
							the verification of Eligible New Customers, the
							tracking of referrals and Eligible New Customers,
							the announcement of the Rewards or in any Referral
							Program-related materials. Persons found tampering
							with or abusing any aspect of this Referral Program,
							who are acting in a disruptive or unsportsmanlike
							manner or who are otherwise not in compliance with
							these terms as solely determined by RTO Mobile will
							be disqualified and all rewards will be void.
							Released Parties are not responsible for injury or
							damage to participants or to any other person's
							computer related to or resulting from participating
							in this Referral Program or downloading materials
							from or use of the RTO Mobile website.
						</p>
						<p>
							RTO Mobile reserves the right at any time to modify,
							suspend or cancel the Referral Program at any time.
							Any changes RTO Mobile makes will be effective
							immediately on notice, which it may give either by
							posting the new terms on the RTO Mobile website(s)
							or via e-mail. Your participation in the Referral
							Program after such notice will be deemed acceptance
							of such changes. You should review these Rules
							periodically to ensure familiarity with the most
							current version. You will always be able to tell
							when the version was last updated by checking the
							“Last Revised” date at the top of these Rules. All
							questions or disputes regarding the Referral
							Program, including without limitation, those
							involving eligibility, participation, fraud and
							abuse will be resolved by RTO Mobile.
						</p>
						<p>
							Your participation in the Referral Program
							constitutes permission for RTO Mobile and its
							designees to use your name, biographical
							information, image, likeness and/or statements about
							the Referral Program for advertising, trade,
							promotional and other purposes, in any manner, in
							any and all media now or hereafter devised,
							worldwide, in perpetuity, without additional
							compensation, notification or permission, unless
							prohibited by law.
						</p>
						<p>
							Except where prohibited, all issues and questions
							concerning the construction, validity,
							interpretation and enforceability of these Rules, or
							the rights and obligations of the participant and
							RTO Mobile in connection with the Referral Program,
							shall be governed by, and construed in accordance
							with, the laws of the State of Colorado, without
							giving effect to any choice of law or conflict of
							law rules (whether of the State of Colorado, or any
							other jurisdiction), which would cause the
							application of the laws of any jurisdiction other
							than the State of Colorado.
						</p>
						<p>
							PERSONAL INFORMATION: Your personal information
							collected in connection with this Program will be
							used in accordance with RTO Mobile’s Privacy Policy,
							available at https://www.RTO Mobile.com/privacy
						</p>
						<p>
							QUESTIONS? Should you have any questions about the
							Referral Program, please call your RTO Mobile
							representative. The Referral Program is offered by
							Rent-to-Own Mobile LLC.
						</p>
					</div>

					<div>
						<div className="rto_results-container"></div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ReferralProgramRules;
