import React, { useState, useEffect, forwardRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Breadcrumb } from "react-bootstrap";
import OpportunityService from "../../service/opportunity/OpportunityService";
import IndexTable from "../../components/common/IndexTable";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
	Button,
	Menu,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
	FormGroup,
	FormControlLabel,
	Switch,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FaPlus, FaMinus } from "react-icons/fa6";
const theme = createTheme({
	palette: {
		default: {
			main: "#ffffff",
			light: "#f7fafc",
			dark: "##f7fafc",
			contrastText: "#2d3748",
		},
		primary: {
			main: "#00ABF9",
			light: "#A3DAFA",
			dark: "#0091d3",
			contrastText: "#fff",
		},
		success: {
			main: "#22c55e",
			light: "#86efac",
			dark: "#16a34a",
			contrastText: "#fff",
		},
		error: {
			main: "#ef4444",
			light: "#fca5a5",
			dark: "#dc2626",
			contrastText: "#fff",
		},
	},
	components: {
		MuiPopover: {
			styleOverrides: {
				paper: {
					padding: "1rem",
					maxWidth: "500px", // Set the width of the dialog window
					maxHeight: "500px", // Set the max height of the dialog window
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: "600",
					textTransform: "none",
					whiteSpace: "nowrap",
				},
			},
		},
	},
});

function Opportunities({ loading, setLoading }) {
	const pageTitle = "Opportunities | RTO Mobile";

	const [opportunitiesData, setOpportunitiesData] = useState([]);
	const [isDataLoaded, setIsDataLoaded] = useState(false);

	const IndexView = {
		page: 1,
		limit: 10,
		sort: "id:desc",
		filter: {},
		load_initial_data: true,
		load_initial_filters: true,
	};

	const fetchOpportunities = async (userFilter = "") => {
		if (activeView !== "pipeline") {
			setIsDataLoaded(true);
			return;
		}
		setLoading(true);
		try {
			const filterOptions = { ...IndexView.filter };
			if (userFilter) {
				filterOptions.assigned_to_id = userFilter;
			}

			const response = await OpportunityService.view({
				...IndexView,
				filter: filterOptions,
				limit: 1000,
			});
			setOpportunitiesData(response);
			setIsDataLoaded(true);

			// Update assignedToOptions if it's in the response
			if (
				response.data &&
				response.data.columns &&
				response.data.columns.assigned_to_fullname_first
			) {
				setAssignedToOptions(
					response.data.columns.assigned_to_fullname_first.data || []
				);
			}

			return response;
		} catch (error) {
			toast.error("Failed to fetch opportunities");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchOpportunities();
	}, []);

	const [activeView, setActiveView] = useState(() => {
		// Initialize state from localStorage
		return localStorage.getItem("activeView") || "list";
	});
	const [anchorEl, setAnchorEl] = useState(null);

	useEffect(() => {
		if (
			activeView === "pipeline" &&
			localStorage.getItem("activeView") === "list"
		) {
			fetchOpportunities();
		}
		// Save activeView to localStorage whenever it changes
		localStorage.setItem("activeView", activeView);
	}, [activeView]);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (view) => {
		setAnchorEl(null);
		if (view) {
			setActiveView(view);
		}
	};

	const [collapsedItems, setCollapsedItems] = useState({});

	const handleToggleExpand = (itemId) => {
		setCollapsedItems((prevCollapsedItems) => ({
			...prevCollapsedItems,
			[itemId]: !prevCollapsedItems[itemId],
		}));
	};

	const handleCollapseAllToggle = (event) => {
		const shouldCollapseAll = event.target.checked;
		const newCollapsedItems = {};

		// Set collapsed state for all items based on the switch
		Object.values(columns).forEach((column) => {
			column.items.forEach((item) => {
				newCollapsedItems[item.id] = shouldCollapseAll;
			});
		});

		setCollapsedItems(newCollapsedItems);
	};

	const [columns, setColumns] = useState({
		"Phone (COLD)": { items: [] },
		"InPerson (COLD)": { items: [] },
		"Referrals (COLD)": { items: [] },
		"SDR (COLD)": { items: [] },
		"UPGRADES (COLD)": { items: [] },
		"Proposing (WARM)": { items: [] },
		"Paperwork Pending": { items: [] },
		"Closed Won (MTD)": { items: [] },
		"Closed Lost": { items: [] },
		"Closed Won (YTD)": { items: [] },
	});

	const formatOpportunitiesData = (data) => {
		const columnMap = {
			"Phone (COLD)": [],
			"InPerson (COLD)": [],
			"Referrals (COLD)": [],
			"SDR (COLD)": [],
			"UPGRADES (COLD)": [],
			"Proposing (WARM)": [],
			"Paperwork Pending": [],
			"Closed Won (MTD)": [],
			"Closed Lost": [],
			"Closed Won (YTD)": [],
		};

		const columnStats = {
			"Phone (COLD)": { count: 0, sum: 0 },
			"InPerson (COLD)": { count: 0, sum: 0 },
			"Referrals (COLD)": { count: 0, sum: 0 },
			"SDR (COLD)": { count: 0, sum: 0 },
			"UPGRADES (COLD)": { count: 0, sum: 0 },
			"Proposing (WARM)": { count: 0, sum: 0 },
			"Paperwork Pending": { count: 0, sum: 0 },
			"Closed Won (MTD)": { count: 0, sum: 0 },
			"Closed Lost": { count: 0, sum: 0 },
			"Closed Won (YTD)": { count: 0, sum: 0 },
		};

		if (Array.isArray(data)) {
			data.forEach((opportunity) => {
				const columnKey = opportunity.opportunities_stage_name;
				if (columnMap.hasOwnProperty(columnKey)) {
					// Push the opportunity to the appropriate column
					columnMap[columnKey].push({
						id: opportunity.id.toString(),
						name: opportunity.name,
						description: opportunity.description,
						store_count: opportunity.store_count,
					});

					// Update the count and sum for the column
					columnStats[columnKey].count += 1;
					columnStats[columnKey].sum += opportunity.store_count || 0;
				}
			});
		}

		const formattedColumns = {};
		Object.keys(columnMap).forEach((key) => {
			formattedColumns[key] = {
				items: columnMap[key],
				count: columnStats[key].count,
				sum: columnStats[key].sum,
			};
		});

		return formattedColumns;
	};

	const STAGE_NAME_TO_ID = {
		"Phone (COLD)": 1,
		"InPerson (COLD)": 2,
		"Referrals (COLD)": 3,
		"SDR (COLD)": 4,
		"UPGRADES (COLD)": 5,
		"Proposing (WARM)": 6,
		"Paperwork Pending": 7,
		"Closed Won (MTD)": 8,
		"Closed Lost": 9,
		"Closed Won (YTD)": 10,
	};

	useEffect(() => {
		if (
			opportunitiesData &&
			opportunitiesData.data &&
			opportunitiesData.data.data
		) {
			const dataItems = opportunitiesData.data.data;
			const formattedColumns = formatOpportunitiesData(dataItems);
			setColumns(formattedColumns);

			// Initialize collapsedItems to collapse all items
			const initialCollapsedItems = {};
			dataItems.forEach((item) => {
				initialCollapsedItems[item.id] = true; // true indicates collapsed
			});
			setCollapsedItems(initialCollapsedItems);
		}
	}, [opportunitiesData]);

	const onDragEnd = (result) => {
		if (!result.destination) return;
		const { source, destination } = result;

		// Save a snapshot of the previous state
		const previousColumns = columns;

		// This will hold the ID of the moved opportunity
		let movedOpportunity = null;

		if (source.droppableId !== destination.droppableId) {
			const sourceColumn = columns[source.droppableId];
			const destColumn = columns[destination.droppableId];
			const sourceItems = [...sourceColumn.items];
			const destItems = [...destColumn.items];

			const [removed] = sourceItems.splice(source.index, 1);
			destItems.splice(destination.index, 0, removed);

			movedOpportunity = removed;

			// Update counts and sums
			const updatedColumns = {
				...columns,
				[source.droppableId]: {
					...sourceColumn,
					items: sourceItems,
					count: sourceColumn.count - 1,
					sum: sourceColumn.sum - (removed.store_count || 0),
				},
				[destination.droppableId]: {
					...destColumn,
					items: destItems,
					count: destColumn.count + 1,
					sum: destColumn.sum + (removed.store_count || 0),
				},
			};

			setColumns(updatedColumns);
		} else {
			const column = columns[source.droppableId];
			const copiedItems = [...column.items];

			const [removed] = copiedItems.splice(source.index, 1);
			copiedItems.splice(destination.index, 0, removed);

			setColumns({
				...columns,
				[source.droppableId]: {
					...column,
					items: copiedItems,
				},
			});

			movedOpportunity = removed;

			// No need to make a network call as the status hasn't changed
			return;
		}

		if (movedOpportunity) {
			const newStageName = destination.droppableId;
			const newStageId = STAGE_NAME_TO_ID[newStageName];

			if (!newStageId) {
				// Revert the UI change by resetting columns state
				setColumns(previousColumns);
				return;
			}

			const payload = {
				data: {
					opportunities_stage_id: newStageId,
				},
			};

			setLoading(true);

			OpportunityService.update(parseInt(movedOpportunity.id), payload)
				.then((response) => {
					setLoading(false);
					if (response.data.isOk) {
						// toast.success(
						// 	"Opportunity status updated successfully"
						// );
						// Optionally, refresh the data or update the local state
					} else {
						toast.error(
							response.msg ||
								"Failed to update opportunity status"
						);
						// Revert the UI change by resetting columns state
						setColumns(previousColumns);
					}
				})
				.catch((error) => {
					setLoading(false);
					toast.error("Error updating opportunity");
					// Revert the UI change by resetting columns state
					setColumns(previousColumns);
				});
		}
	};

	const [selectedUser, setSelectedUser] = useState("");
	const [filterList, setFilterList] = useState({});
	const [assignedToOptions, setAssignedToOptions] = useState([]);

	const updateAssignedToOptions = (options) => {
		setAssignedToOptions(options);
	};

	const handleUserChange = (event) => {
		const newValue = event.target.value;
		setSelectedUser(newValue);

		const newFilterList = { ...filterList };
		if (newValue) {
			newFilterList.assigned_to_id = newValue;
		} else {
			delete newFilterList.assigned_to_id;
		}
		setFilterList(newFilterList);

		if (activeView === "pipeline") {
			fetchOpportunities(newValue);
		}
	};

	const allItems = Object.values(columns).flatMap((column) => column.items);
	const areAllCollapsed =
		allItems.length > 0 &&
		allItems.every((item) => collapsedItems[item.id]);

	const BootstrapTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} arrow classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
			color: theme.palette.common.black,
		},
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.black,
		},
	}));

	const DraggableItem = forwardRef(
		({ item, isExpanded, handleToggleExpand, provided }, ref) => (
			<div
				ref={ref}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
				className="border-y"
				style={{
					userSelect: "none",
					margin: "0 0 0px 0",
					minHeight: "50px",
					backgroundColor: "white",
					color: "black",
					marginBottom: "-1px",
					...provided.draggableProps.style,
				}}
			>
				<BootstrapTooltip
					title={
						<>
							<div>
								<strong>Name:</strong> {item.name}
							</div>
							<div>
								<strong>Store Count:</strong> {item.store_count}
							</div>
							<div>
								<strong>Description:</strong> {item.description}
							</div>
						</>
					}
					placement="right"
					arrow
				>
					<div
						style={{
							padding: "16px",
							position: "relative",
							maxHeight: isExpanded ? "none" : "100px",
							overflow: "hidden",
						}}
					>
						{item.name}
						<span
							className="d-block small"
							style={{
								opacity: ".7",
							}}
						>
							{item.description}
						</span>
						{isExpanded ? (
							<FaMinus
								style={{
									color: "#00ABF9",
									position: "absolute",
									bottom: "5px",
									right: "5px",
									cursor: "pointer",
								}}
								onClick={() => handleToggleExpand(item.id)}
							/>
						) : (
							<FaPlus
								style={{
									color: "#00ABF9",
									position: "absolute",
									bottom: "5px",
									right: "5px",
									cursor: "pointer",
								}}
								onClick={() => handleToggleExpand(item.id)}
							/>
						)}
					</div>
				</BootstrapTooltip>
			</div>
		)
	);

	return (
		<>
			<ThemeProvider theme={theme}>
				<Helmet>
					<title>{pageTitle}</title>
					<meta name="robots" content="noindex,nofollow"></meta>
					<meta property="og:title" content={pageTitle} />
					<meta name="twitter:title" content={pageTitle} />
				</Helmet>
				<div className="rto_form-sections">
					<div
						className="rto_content-div"
						style={{ maxWidth: "1600px" }}
					>
						<Breadcrumb
							listProps={{
								className: "w-100 justify-content-end",
							}}
						>
							<Breadcrumb.Item
								linkAs={Link}
								linkProps={{ to: "/dashboard" }}
							>
								Dashboard
							</Breadcrumb.Item>
							<Breadcrumb.Item
								linkAs={Link}
								linkProps={{ to: "/admin" }}
							>
								Admin
							</Breadcrumb.Item>
							<Breadcrumb.Item active>
								Opportunities
							</Breadcrumb.Item>
						</Breadcrumb>
						<div className="rto_title-div">
							<h1 className="rto_page-title">Opportunities</h1>
						</div>
						{/* <p className="text-center">
							<Link
								to="/opportunities/create"
								className="btn btn-primary"
							>
								Create
							</Link>
						</p> */}

						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								mb: 3,
							}}
						>
							{activeView === "pipeline" && (
								<FormGroup>
									<FormControlLabel
										control={
											<Switch
												checked={areAllCollapsed}
												onChange={
													handleCollapseAllToggle
												}
											/>
										}
										label="Collapse All"
									/>
								</FormGroup>
							)}

							{activeView === "pipeline" && (
								<FormControl
									sx={{ minWidth: 120, mr: 2 }}
									size="small"
								>
									<InputLabel id="user-select-label">
										User
									</InputLabel>
									<Select
										labelId="user-select-label"
										id="user-select"
										value={selectedUser}
										onChange={handleUserChange}
										label="User"
									>
										<MenuItem value="">
											--- All Users ---
										</MenuItem>
										{assignedToOptions.map((option) => (
											<MenuItem
												key={option.id}
												value={option.id.toString()}
											>
												{option.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}

							<Button
								variant="contained"
								disableElevation
								color="primary"
								onClick={handleClick}
								endIcon={<ArrowDropDownIcon />}
								sx={{ mb: 3, py: 1 }}
							>
								<span className="font-normal text-base">
									{activeView === "list"
										? "List View"
										: "Pipeline View"}
								</span>
							</Button>
							<Menu
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={() => handleClose()}
							>
								<MenuItem onClick={() => handleClose("list")}>
									List View
								</MenuItem>
								<MenuItem
									onClick={() => handleClose("pipeline")}
								>
									Pipeline View
								</MenuItem>
							</Menu>
						</div>

						{activeView === "list" ? (
							<div
								className="card card-outline card-primary"
								style={{
									borderTop: "3px solid #00aaff",
									boxShadow:
										"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
								}}
							>
								<div className="card-body">
									{isDataLoaded && (
										<IndexTable
											model={"opportunities"}
											loading={loading}
											setLoading={setLoading}
											service={OpportunityService}
											title="Opportunities"
											actionButtons={
												{
													// updateLink: {
													// 	path: "/opportunities",
													// },
													// updateModal: {
													// 	enabled: true,
													// 	path: "/leads/update",
													// },
													// create: {
													// 	enabled: true,
													// 	path: "/leads/create",
													// },
												}
											}
											labels={[
												{
													id: "id",
													label: "ID",
												},
												{
													id: "name",
													label: "Name",
												},
												{
													id: "model_name",
													label: "Model",
													model_link_alt: true,
												},
												{
													id: "store_count",
													label: "Store Count",
												},
												{
													id: "opportunities_stage_name",
													label: "Stage",
												},
												{
													id: "close_date",
													label: "Close Date",
												},
												{
													id: "description",
													label: "Description",
													initialDisplay: false,
												},
												{
													// duplicate hidden column to adjust the link
													id: "model_id",
													label: "Model ID",
													excluded: true,
												},
												{
													// duplicate hidden column to adjust the link
													id: "model_id",
													label: "Model ID",
													excluded: true,
												},
												{
													// duplicate hidden column to adjust the link
													id: "model",
													label: "Model",
													excluded: true,
												},
												{
													// duplicate hidden column to adjust the link
													id: "model_name",
													label: "Model Name",
													excluded: true,
												},
												{
													id: "assigned_to_fullname_first",
													label: "Assigned To",
												},
												{
													id: "created_at",
													label: "Created At",
												},
												// {
												// 	id: "created_to_fullname_first",
												// 	label: "Created By",
												// 	initialDisplay: false,
												// },
											]}
											apiBase="/opportunity"
											successRedirectBase="opportunities"
											externalData={opportunitiesData}
											selectedUser={selectedUser}
											externalFilterList={filterList}
											onUserChange={handleUserChange}
											onUpdateAssignedToOptions={
												updateAssignedToOptions
											}
										/>
									)}
								</div>
							</div>
						) : (
							<div
								className="card card-outline card-primary"
								style={{
									borderTop: "3px solid #00aaff",
									boxShadow:
										"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
								}}
							>
								<div
									className="card-body"
									style={{ overflow: "auto" }}
								>
									<DragDropContext onDragEnd={onDragEnd}>
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
												height: "100%",
											}}
										>
											{Object.entries(columns).map(
												([columnId, column]) => (
													<div
														className="border rounded-lg"
														key={columnId}
														style={{
															display: "flex",
															flexDirection:
																"column",
															alignItems:
																"center",
															margin: 2.5,
														}}
													>
														<div
															className="rounded-t border-b w-full bg-slate-50 py-[1.25rem] px-[.75rem]"
															style={{
																...(column.items
																	.length >
																	0 && {
																	marginBottom:
																		"-1px",
																}),
															}}
														>
															<h5>{columnId}</h5>
															<span
																className="d-block small"
																style={{
																	whiteSpace:
																		"nowrap",
																	opacity:
																		".7",
																}}
															>
																{column.count}{" "}
																OPPORTUNITIES -{" "}
																{column.sum}{" "}
																STORES
															</span>
														</div>
														<div
															style={{
																height: "100%",
															}}
														>
															<Droppable
																droppableId={
																	columnId
																}
																key={columnId}
															>
																{(
																	provided,
																	snapshot
																) => (
																	<div
																		{...provided.droppableProps}
																		ref={
																			provided.innerRef
																		}
																		style={{
																			background:
																				snapshot.isDraggingOver
																					? "#bae6fd"
																					: "white",
																			padding: 0,
																			width: 250,
																			minHeight: 500,
																			height: "100%",
																			overflow:
																				"hidden",
																		}}
																		className="rounded-b"
																	>
																		{column.items.map(
																			(
																				item,
																				index
																			) => {
																				const isExpanded =
																					!collapsedItems[
																						item
																							.id
																					];

																				return (
																					<Draggable
																						key={
																							item.id
																						}
																						draggableId={
																							item.id
																						}
																						index={
																							index
																						}
																					>
																						{(
																							provided,
																							snapshot
																						) => (
																							<DraggableItem
																								ref={
																									provided.innerRef
																								}
																								item={
																									item
																								}
																								isExpanded={
																									isExpanded
																								}
																								handleToggleExpand={
																									handleToggleExpand
																								}
																								provided={
																									provided
																								}
																							/>
																						)}
																					</Draggable>
																				);
																			}
																		)}
																		{/* {
																			provided.placeholder
																		} */}
																	</div>
																)}
															</Droppable>
														</div>
													</div>
												)
											)}
										</div>
									</DragDropContext>
								</div>
							</div>
						)}
					</div>
				</div>
			</ThemeProvider>
		</>
	);
}

export default Opportunities;
