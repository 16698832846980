import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import SessionService from "../../service/SessionService";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
	FaHandshake,
	FaComments,
	FaPhone,
	FaPencil,
	FaAt,
	FaPaperPlane,
	FaListCheck,
	FaUserPlus,
	FaBuilding,
	FaStar,
	FaBell,
	FaCircleCheck,
	FaFileExport,
	FaTrophy,
	FaTriangleExclamation,
	FaClock,
} from "react-icons/fa6";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import CustomSelectComponent from "../../components/formComponents/rtoDynamicForm/fields/CustomSelectComponent";
import { DateRangePicker } from "../../components/common/date-range-picker-mui";
const theme = createTheme({
	palette: {
		default: {
			main: "#ffffff",
			light: "#f7fafc",
			dark: "##f7fafc",
			contrastText: "#2d3748",
		},
		primary: {
			main: "#00ABF9",
			light: "#A3DAFA",
			dark: "#0091d3",
			contrastText: "#fff",
		},
		success: {
			main: "#22c55e",
			light: "#86efac",
			dark: "#16a34a",
			contrastText: "#fff",
		},
		error: {
			main: "#ef4444",
			light: "#fca5a5",
			dark: "#dc2626",
			contrastText: "#fff",
		},
	},
	components: {
		MuiPopover: {
			styleOverrides: {
				paper: {
					padding: "1rem",
					maxWidth: "500px", // Set the width of the dialog window
					maxHeight: "500px", // Set the max height of the dialog window
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: "600",
					textTransform: "none",
					whiteSpace: "nowrap",
				},
			},
		},
	},
});

function SalesDashboard() {
	const pageTitle = "Sales Dashboard | RTO Mobile";

	const filterList = {
		created_at: [
			{
				from: "2024-03-01",
				to: "2024-03-31",
			},
		],
		user: ["0"], // Assuming '0' represents 'All' users
		status: [],
		lead_source: [],
		assigned_to: [],
		tags: [],
		// Add other filter categories as needed
	};

	return (
		<>
			<ThemeProvider theme={theme}>
				<Helmet>
					<title>{pageTitle}</title>
					<meta name="robots" content="noindex,nofollow"></meta>
					<meta property="og:title" content={pageTitle} />
					<meta name="twitter:title" content={pageTitle} />
				</Helmet>
				<div className="rto_form-sections">
					<div
						className="rto_content-div"
						style={{ maxWidth: "1600px" }}
					>
						<Breadcrumb
							listProps={{
								className: "w-100 justify-content-end",
							}}
						>
							<Breadcrumb.Item
								linkAs={Link}
								linkProps={{ to: "/dashboard" }}
							>
								Dashboard
							</Breadcrumb.Item>
							<Breadcrumb.Item
								linkAs={Link}
								linkProps={{ to: "/admin" }}
							>
								Admin
							</Breadcrumb.Item>
							<Breadcrumb.Item active>
								Sales Dashboard
							</Breadcrumb.Item>
						</Breadcrumb>
						<div className="rto_title-div">
							<h1 className="rto_page-title">Sales Dashboard</h1>
						</div>
						<div>
							<section className="text-neutral-800 px-2">
								<div className="px-2 w-full m-auto">
									<div>
										<div>
											<div className="row">
												<div className="col">
													<div className="flex">
														<h1 className="text-[2.50rem] leading-none font-medium inline-block mb-2">
															Prospects
														</h1>
														&nbsp;&nbsp;
														<div className="inline-block">
															<div className="row">
																<div className="col">
																	<CustomSelectComponent
																		name="user"
																		label="User"
																		value="0"
																		onChange={() => {}}
																		type={{
																			options:
																				[
																					{
																						name: "All",
																						id: "0",
																					},
																				],
																		}}
																	/>
																</div>

																<div className="col">
																	<DateRangePicker
																		onUpdate={(
																			values
																		) => {
																			const formatDate =
																				(
																					date
																				) => {
																					if (
																						!date
																					)
																						return "";
																					const d =
																						new Date(
																							date
																						);
																					const month =
																						d.getMonth() +
																						1;
																					const day =
																						d.getDate();
																					const year =
																						d.getFullYear();
																					return `${year}-${month}-${day}`;
																				};
																			const startDate =
																				formatDate(
																					values
																						.range
																						.from
																				);
																			const endDate =
																				formatDate(
																					values
																						.range
																						.to
																				);
																			const dateRangeObj =
																				!endDate
																					? {
																							from: startDate,
																							to: startDate,
																					  }
																					: {
																							from: startDate,
																							to: endDate,
																					  };

																			filterList[
																				"created_at"
																			][0] =
																				dateRangeObj;

																			// DataView.filter =
																			// 	{
																			// 		...DataView.filter,
																			// 		ranges: {
																			// 			...DataView
																			// 				.filter
																			// 				.ranges,
																			// 			["created_at"]:
																			// 				dateRangeObj,
																			// 		},
																			// 	};

																			// const updatedFilters =
																			// 	{
																			// 		...filters,
																			// 		ranges: {
																			// 			...filters.ranges,
																			// 			['created_at']:
																			// 				dateRangeObj,
																			// 		},
																			// 	};

																			// setFilters(
																			// 	updatedFilters
																			// );

																			// onChange(
																			// 	filterList[
																			// 		'created_at'
																			// 	],
																			// 	'created_at',
																			// 	column
																			// );
																		}}
																		label={
																			"Created At"
																		}
																		initialDateFrom={
																			filterList[
																				"created_at"
																			][0]
																				? filterList[
																						"created_at"
																				  ][0][
																						"from"
																				  ]
																				: ""
																		}
																		initialDateTo={
																			filterList[
																				"created_at"
																			][0]
																				? filterList[
																						"created_at"
																				  ][0][
																						"to"
																				  ]
																				: ""
																		}
																		align="end"
																		locale="en-GB"
																		showCompare={
																			false
																		}
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col">
													<h4 className="text-2xl font-medium mb-2">
														Activity By Date
													</h4>

													<div className="row">
														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaPhone className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		Calls
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaComments className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		Texts
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaHandshake className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		Meetings
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaPencil className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		Notes
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaAt className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		Emails
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaPaperPlane className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		Social
																		Messages
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaListCheck className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		Activities
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0
																	</h5>
																</CardContent>
															</Card>
														</div>
													</div>

													<br />

													<div className="row">
														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaUserPlus className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		New
																		Leads
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaBuilding className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		New
																		Stores
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaStar className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		New
																		Opportunities
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaBell className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		New
																		Reminders
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaCircleCheck className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		Reminders
																		Completed
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaFileExport className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		Onboardings
																		Sent
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col"></div>
													</div>
												</div>
											</div>

											<br />

											<div className="row">
												<div className="col">
													<h4 className="text-2xl font-medium mb-2">
														Your Pipelines
													</h4>

													<div className="row">
														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaStar className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		Open
																		Opportunities
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0 |
																		$0.00
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaTrophy className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		Closed
																		Won
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0 |
																		$0.00
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaTriangleExclamation className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		Closed
																		Lost
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0 |
																		$0.00
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaTrophy className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		Closed
																		Won YTD
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0 |
																		$0.00
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaTriangleExclamation className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		Closed
																		Lost YTD
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0 |
																		$0.00
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col"></div>

														<div className="col"></div>
													</div>
												</div>
											</div>

											<br />

											<div className="row">
												<div className="col">
													<h4 className="text-2xl font-medium mb-2">
														Reminders and Events
													</h4>

													<div className="row">
														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaBell className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		Past Due
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaBell className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		Due
																		Today
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaBell className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		Due This
																		Week
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col">
															<Card variant="outlined">
																<CardContent className="text-center">
																	<FaClock className="mx-auto text-[#00ABF9]" />
																	<p className="text-sm mb-4">
																		Total
																		Open
																	</p>
																	<h5 className="text-xl font-medium mb-2">
																		0
																	</h5>
																</CardContent>
															</Card>
														</div>

														<div className="col"></div>

														<div className="col"></div>

														<div className="col"></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</ThemeProvider>
		</>
	);
}

export default SalesDashboard;
