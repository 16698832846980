import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle,
	useRef,
} from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

const CustomTimeInputComponent = forwardRef(
	(
		{
			name,
			label,
			type,
			value,
			onChange,
			onBlur,
			className,
			required,
			...otherProps
		},
		ref
	) => {
		const formatValue = (valueToValidate) => {
			return valueToValidate ? dayjs(valueToValidate, "HH:mm") : null;
		};

		const [internalValue, setInternalValue] = useState(formatValue(value));
		const [isValid, setIsValid] = useState(true);
		const [errorMessage, setErrorMessage] = useState("");
		const [isTouched, setIsTouched] = useState(false);
		const isTouchedRef = useRef(isTouched);
		const [isMounted, setIsMounted] = useState(false);
		const inputRef = useRef(null);

		useEffect(() => {
			setInternalValue(formatValue(value));
		}, [value]);

		useEffect(() => {
			if (isMounted) {
				validate(internalValue);
			} else {
				setIsMounted(true);
			}
		}, [internalValue]);

		useEffect(() => {
			isTouchedRef.current = isTouched;
		}, [isTouched]);

		useImperativeHandle(ref, () => ({
			validate: (value) => validate(formatValue(value)),
			isTouched: () => isTouchedRef.current,
			setIsTouched: (value) => {
				setIsTouched(value);
				isTouchedRef.current = value;
			},
			focus: () => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
			},
		}));

		const validate = (valueToValidate) => {
			let valid = true;
			let message = "";
			if (required && !valueToValidate) {
				valid = false;
				message = "This field is required.";
				setIsTouched(true);
			}
			// Add any additional time-specific validations here

			setIsValid(valid);
			setErrorMessage(message);
			if (onChange) {
				onChange(
					name,
					valueToValidate ? valueToValidate.format("HH:mm") : "",
					valid
				);
			}
			return valid;
		};

		const handleOnChange = (newValue) => {
			setIsTouched(true);
			setInternalValue(newValue);
			validate(newValue);
		};

		const handleOnBlur = (e) => {
			setIsTouched(true);
			validate(internalValue);
			if (onBlur) onBlur(e);
		};

		const fieldInfo = {
			upperCase: type && type.upperCase,
			style: type && type.style,
			readOnly: type && type.readOnly,
		};

		const transformedLabel = fieldInfo.upperCase
			? label.toUpperCase()
			: label;

		return (
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<TimePicker
					label={transformedLabel}
					value={internalValue}
					onChange={handleOnChange}
					onBlur={handleOnBlur}
					className={className}
					slotProps={{
						textField: {
							variant: "standard",
							error: !isValid && isTouchedRef.current,
							helperText:
								!isValid && isTouchedRef.current
									? fieldInfo.upperCase
										? errorMessage.toUpperCase()
										: errorMessage
									: "",
							inputProps: {
								readOnly: fieldInfo.readOnly,
								style: {
									...fieldInfo.style,
									textTransform: fieldInfo.upperCase
										? "uppercase"
										: "none",
								},
							},
						},
					}}
					readOnly={fieldInfo.readOnly}
					{...otherProps}
					inputRef={inputRef}
				/>
			</LocalizationProvider>
		);
	}
);

export default CustomTimeInputComponent;
