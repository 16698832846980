import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import SessionService from "../../service/SessionService";
import { FaDolly } from "react-icons/fa";
import { BiSolidContact } from "react-icons/bi";

function Admin() {
	const pageTitle = "Admin | RTO Mobile";

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="robots" content="noindex,nofollow"></meta>
				<meta property="og:title" content={pageTitle} />
				<meta name="twitter:title" content={pageTitle} />
			</Helmet>
			<div className="rto_form-sections">
				<div className="rto_content-div rto_900">
					<Breadcrumb
						listProps={{ className: "w-100 justify-content-end" }}
					>
						<Breadcrumb.Item
							linkAs={Link}
							linkProps={{ to: "/dashboard" }}
						>
							Dashboard
						</Breadcrumb.Item>
						<Breadcrumb.Item active>Admin</Breadcrumb.Item>
					</Breadcrumb>
					<div className="rto_title-div">
						<h1 className="rto_page-title">Admin Dashboard</h1>
					</div>
					<div>
						<div className="rto_results-container">
							<div className="rto_div-block-27">
								{SessionService.canSystemAdmin() && (
									<div
										className="rto_result-contract card border-bottom-0 border-right-0 border-left-0 card-primary"
										style={{
											borderTop: "3px solid #00aaff",
										}}
									>
										<h3
											className="rto_contract-h3-preview-title d-flex align-items-center"
											style={{
												fontWeight: 400,
											}}
										>
											<b>
												<FaDolly
													style={{
														color: "#00aaff",
													}}
												/>
											</b>
											&nbsp;Vendor Settings
										</h3>
										<ul>
											<li>
												<Link
													to="/admin/vendors"
													className="rto_link-2"
												>
													Vendors
												</Link>
											</li>
											<li>
												<Link
													to="/admin/vendor-products"
													className="rto_link-2"
												>
													Vendor Products
												</Link>
											</li>
											<li>
												<Link
													to="/admin/vendors-manufacturers"
													className="rto_link-2"
												>
													Vendors - Manufacturers
												</Link>
											</li>
											<li>
												<Link
													to="/admin/vendors-models"
													className="rto_link-2"
												>
													Vendors - Models
												</Link>
											</li>
										</ul>
									</div>
								)}
								<div
									className="rto_result-contract card border-bottom-0 border-right-0 border-left-0 card-primary"
									style={{
										borderTop: "3px solid #00aaff",
									}}
								>
									<h3
										className="rto_contract-h3-preview-title d-flex align-items-center"
										style={{
											fontWeight: 400,
										}}
									>
										<b>
											<BiSolidContact
												style={{
													color: "#00aaff",
												}}
											/>
										</b>
										&nbsp;Prospects
									</h3>
									<ul>
										<li>
											<Link
												to="/sales-dashboard"
												className="rto_link-2"
											>
												Sales Dashboard
											</Link>
										</li>
										<li>
											<Link
												to="/leads"
												className="rto_link-2"
											>
												Leads
											</Link>
										</li>
										<li>
											<Link
												to="/opportunities"
												className="rto_link-2"
											>
												Opportunities
											</Link>
										</li>
										<li>
											<Link
												to="/activities"
												className="rto_link-2"
											>
												Activities
											</Link>
										</li>
										<li>
											<Link
												to="/reminders"
												className="rto_link-2"
											>
												Reminders
											</Link>
										</li>
										<li>
											<a
												href="/onboarding"
												className="rto_link-2"
											>
												Onboarding
											</a>
										</li>
										<li>
											<a
												href="/onboarding-v2"
												className="rto_link-2"
											>
												Onboarding V2
											</a>
										</li>
										<li>
											<Link
												to="/contacts"
												className="rto_link-2"
											>
												Contacts
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Admin;
